import React, {useEffect} from 'react';
import {AngelForm} from '../components';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID

export const Contact = () => {
  useEffect(() => {
    ReactGA.initialize([{ trackingId: TRACKING_ID }]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact" });
  }, []);
  return (
    <div className={'md:bg-transparent bg-white px-2 mx-4  mt-20 xs:mt-0 rounded-xl'}>
      <AngelForm contact />
    </div>
  )
}

export default Contact

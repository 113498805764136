import React, {useState, useEffect, useRef} from 'react';
import './AngelHero.css'
import {Link} from 'react-router-dom';
import FrameSvg from './FrameSvg';
import realitycult from '../../assets/images/realitycult.png'
import {useSwipeable} from 'react-swipeable'


export const AngelHero = ({slider}) => {

  const [slideTransitionActive, setSlideTransitionActive] = useState(false)
  const [preventAutoplay, setPreventAutoplay] = useState(false)

  const [ slides, setSlides] = useState(slider)

  const config = {
    delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
    trackTouch: true,                      // track touch input
    trackMouse: false,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
  }

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => nextSlide(),
    onSwipedRight: (eventData) => prevSlide(),
    ...config
  })

  const prevSlide = (preventAutoplay) => {
    setPreventAutoplay(preventAutoplay)
    setSlides(s => [s[slides.length - 1],[...s.slice(0, slides.length - 2)]])
  }

  const nextSlide = (preventAutoplay) => {
    setPreventAutoplay(preventAutoplay)
    setSlideTransitionActive(true)
  }

  const containerRef = useRef();

  useEffect(() => {
    const interval = setInterval(function () {
      if(!preventAutoplay) {
        nextSlide()
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    const time = setTimeout(function () {
      if(slideTransitionActive) {
        setSlides(s => [...s.slice(1), s[0]])
      }
    }, 1000);
  }, [slideTransitionActive])

  useEffect(() => {
    setSlideTransitionActive(false)
  }, [slides])

    return(
      <>

      {/*<div className={`absolute left-0 w-full h-full -z-[1] hue-rotate-60 invert top-0 brightness-[0.9] transition duration-1000 ${slideTransitionActive ? 'opacity-1' : 'opacity-0'}`}>
      <img src={bg} />
      </div>*/}
      <div ref={containerRef} {...handlers} className={`sm:mt-20 xl:mt-16 relative w-full flex justify-between   ${slideTransitionActive ? 'translate-x-[0px]' : ''}`}>
      <div className={'absolute top-[78px] z-[9999] sm:-top-[5px] w-full'}>
        <p className={'sm:hidden font-heading text-giek-dark-purple glowing-purple-text text-5xl font-bold text-center w-full pl-0'}>reality cult</p>
        <img className={'hidden sm:block'} src={realitycult} />
      </div>
        <FrameSvg classes={`opacity-1 sm:hidden translate-y-[30px] md:translate-y-[44px] lg:translate-y-[59px] xl:translate-y-[90px] -scale-x-[1]`} />
        <FrameSvg classes={`translate-y-[30px] sm:opacity-0 `} />
        <div className={` absolute right-[55px] lg:top-[6px] xl:top-[37px] rotate-[180deg] origin-bottom-right`}>
          <FrameSvg classes={`-scale-y-[1] -scale-x-[1]`} />
        </div>
        <div className={` absolute -left-[54px] lg:top-[6px] xl:top-[37px] rotate-[180deg] scale-y-[1] origin-bottom-right`}>
          <FrameSvg classes={'-scale-y-[1]'} />
        </div>

        {/* Head */}
        <div className={'blur-panel-light z-10 absolute scale-[0.888] sm:scale-1 sm:right-[1rem] top-[108px] sm:top-[108px] xl:top-[144px] sm:w-[47.77%] rounded-[260px] p-0 sm:p-0 border border-giek-grey'}>
          <div className={'angel-image-frame absolute h-full rounded-[260px] left-0 top-0 opacity-100'}></div>
          <div className={'angel-image-frame absolute  h-full rounded-[260px] left-0 top-0 opacity-100'}></div>
          <img className={`w-full h-auto rounded-[260px] ${slideTransitionActive ? 'transition duration-1000 opacity-0' : 'fade-in'}`} src={slides[0].image} />
        </div>

        {/* Body */}
        <div className={'px-8 sm:px-0 absolute z-[999999] sm:border-none flex items-between sm:h-auto sm:left-[60px] w-full -bottom-[42px] no-blur-panel sm:blur-panel-hero sm:blur-panel-light sm:py-4 py-0 sm:bottom-auto sm:top-[45px] md:top-[30px] lg:top-[60px] xl:top-[120px] sm:w-[280px] md:w-[270px] lg:w-[320px] xl:w-[420px] h-[180px] sm:h-[420px] md:h-[420px] flex justify-center items-center'}>
        <Link to={slides[0].external_link ? slides[0].external_link : slides[0].link}>
          <div className={`px-4  ${slideTransitionActive ? 'opacity-0 transition duration-1000' : 'fade-in'}`}>
            <h2 className={'text-center  sm:text-left font-primary mb-2 text-4xl text-outlined-white sm:no-text-outlined text-white sm:text-black xl:pl-4 md:text-xl lg:text-2xl xl:text-5xl sm:my-4 uppercase font-bold'}>
              {slides[0].title}
            </h2>
            <h6 className={'xl:pl-4 hidden sm:block font-secondary md:text-sm lg:text-md xl:text-lg my-2 font-bold sm:my-8'}>
              {slides[0].subtitle}
            </h6>
            <div className={'xl:ml-4 drop-shadow-cta p-1 rounded-2xl bg-cta-container-bg mt-2 w-full max-w-[320px] flex justify-center'}>
              <button className={'font-secondary text-xl w-full bg-gradient-to-r from-cta-grad-from to-cta-grad-to rounded-xl px-2 py-1 uppercase'}>{slides[0].cta}</button>
            </div>
          </div>
          </Link>
        </div>
        {/* Current Flavour */}


        <div className={'font-secondary z-[999] sm:z-[1] italic absolute sm:left-[53px] text-md blur-panel-hero md:text-md -bottom-[196px] md:-bottom-[45px] lg:-bottom-[91px] xl:-bottom-[182px] w-[300vw] sm:w-[840px] h-[150px] md:h-[180px] overflow-hidden blur-panel'}>
        { slides &&
          slides.map((slide, i) => (
            <div className={`
              ${i == 0 ? 'left-[0px] border-l' : ''}
              ${i == 1 ? 'left-[100vw] sm:left-[350px] md:left-[420px] border-l' : ''}
              ${i == 2 ? 'left-[200vw] sm:left-[700px] md:left-[840px] border-l border-r' : ''}
              ${i >= 3 ? 'left-[300vw] sm:left-[1050px] md:left-[1260px] border-l border-r' : ''}
              ${slideTransitionActive ? '-translate-x-[100vw] sm:-translate-x-[350px] md:-translate-x-[420px] transition duration-1000 ease-in-out' : ''}
               absolute w-[100vw] sm:w-[350px] md:w-[420px] h-[150px] md:h-[180px] border-t border-b border-gray-400 flex justify-center items-center`}>
              <p className={'px-8'}>
              {slide.copy}
              </p>
            </div>
          ))
        }
        </div>

        <div className={`-z-[1] absolute right-[1rem] top-[120px] w-[50%]  rounded-[260px] p-4 transition duration-1000 ${slideTransitionActive ? ' opacity-100' : 'opacity-0 '}`}>
                <div className={' angel-image-frame absolute w-full h-full rounded-[260px] left-0  top-0 opacity-100 scale-[1.333]'}></div>
                <div className={' angel-image-frame absolute w-full h-full rounded-[260px] left-0 top-0 opacity-80 scale-[1.777] '}></div>

                <img className={`w-full h-auto rounded-[260px] hidden ${slideTransitionActive ? 'transition duration-1000 brightness-[1] saturate-[0] opacity-0' : ''}`} src={slides[0].image} />
        </div>

      </div>
      </>
    )
}

export default AngelHero;

import React, {useState, useEffect} from 'react'
import axios from 'axios';
import TimeZoneSelector from './TimeZoneSelector';

export const AngelForm = ({service, contact}) => {

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formHasSubmitted, setFormHasSubmitted] = useState(false);
  const [formErr, setFormErr] = useState(null)

  const [formPurpose, setFormPurpose] = useState(null)
  const [formService, setFormService] = useState(null)
  const [formName, setFormName] = useState(null)
  const [formEmail, setFormEmail] = useState(null)
  const [formTimezone, setFormTimezone] = useState('GMT')
  const [formTimeOfDay, setFormTimeOfDay] = useState('evenings')
  const [mondayOn, setMondayOn] = useState(false)
  const [tuesdayOn, setTuesdayOn] = useState(false)
  const [wednesdayOn, setWednesdayOn] = useState(false)
  const [thursdayOn, setThursdayOn] = useState(false)
  const [fridayOn, setFridayOn] = useState(false)
  const [saturdayOn, setSaturdayOn] = useState(false)
  const [sundayOn, setSundayOn] = useState(false)
  const [formExtraInfo, setFormExtraInfo] = useState(null)

  const handleSubmit = async () => {
    if(!formName) {
      setFormErr('Please enter your name')
    } else if (!formEmail) {
      setFormErr('Please enter your email')
    } else {
    try {
      const payload = {
        formname: formName,
        formemail: formEmail,
        formpurpose: contact ?
            `${formPurpose == 'art-commissions' ? 'Art Commissions' : ''}${formPurpose == 'bookings' ? 'Bookings' : ''}${formPurpose == 'giekonstruct-customised-clothing' ? 'Giekonstruct Customised Clothing' : ''}${formPurpose == 'giekonstruct-theatre-decor' ? 'Giekonstruct Theatre Decor' : ''}${formPurpose == 'spiritual-guidance' ? 'Spiritual Guidance' : ''}`
          : '',
        formservice: contact ?

          `${formService == 'channelled-personal-messages' ?  'Personal Channelled Messages' : ''}${formService == 'personal-channelled-love-messages' ?  'Personal Channelled Love Messages' : ''}${formService == 'general-life-purpose' ?  'General Life Purpose' : ''}${formService == 'past-life-tarot' ?  'Past Life Tarot' : ''}${formService == 'distance-energy-healing' ?  'Distance Energy Healing' : ''}${formService == 'magick-healing-sigil' ?  'Magick Healing Sigil' : ''}${formService == 'spirital-awakening-coaching' ?  'Spiritual Awakening Coaching' : ''}`
        : '',
        formextrainfo: formExtraInfo,
        formtimezone: formTimezone,
        formtimeday: formTimeOfDay,
        formdayofweek: `${mondayOn ? 'Monday ' : ''}${tuesdayOn ? 'Tuesday ' : ''}${wednesdayOn ? 'Wednesday ' : ''}${thursdayOn ? 'Thursday ' : ''}${fridayOn ? 'Friday ' : ''}${saturdayOn ? 'Saturday ' : ''}${sundayOn ? 'Sunday ' : ''}`,
      }

      const formData = new FormData()
      formData.append('formname', payload.formname)
      formData.append('formemail', payload.formemail)
      formData.append('formpurpose', payload.formpurpose)
      formData.append('formservice', payload.formservice)
      formData.append('formextrainfo', payload.formextrainfo)
      formData.append('formtimezone', payload.formtimezone)
      formData.append('formtimeday', payload.formtimeday)
      formData.append('formdayofweek', payload.formdayofweek)
      formData.append('_wpcf7_unit_tag', 'f656-p2-o1');

      const formSubmission = await axios.post('https://admin.reality-cult.com/wp-json/contact-form-7/v1/contact-forms/656/feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log(formSubmission)
      if(formSubmission) {
        setFormHasSubmitted(true)
      }
    } catch (err) {
      console.log(err)
    }
  }
  }

  return (
    <div className={'py-8 xs:p-8 max-w-screen-sm mx-auto'}>
    <div className={'md:px-12'}>
    <h1 className={`text-center font-heading font-bold text-giek-purple my-8 mb-2 ${contact ? 'xs:mt-0 xs:mb-4 text-6xl' : 'text-3xl'}`}>{contact ? 'Contact' : 'Book your spiritual session'}</h1>

    {!formHasSubmitted && <>
    {contact && <div className={'grid grid-cols-2'}>
      <div className={'form-control col-span-1 p-2 my-1'}>
      <label for="timezone-select" className={'block font-secondary'}>Contact Purpose</label>
      <select onChange={(e) => setFormPurpose(e.target.value)} className={'font-secondary py-[0.375rem] px-2 input border border-giek-purple rounded-md text-lg w-full input-bordered'} id="timezone-select">
        <option value =""></option>
        <option value="art-commissions">Art Commissions</option>
        <option value="bookings">Bookings</option>
        <option value="giekonstruct-customised-clothing">GIEKONSTRUCT (Customised Clothing Pieces / Theatre Costumes)</option>
        <option value="giekonstruct-theatre-decor">GIEKONSTRUCT (Theatre Decor)</option>
        <option value="spiritual-guidance">Spiritual Guidance</option>
      </select>
      </div>
      <div className={'form-control col-span-1 p-2 my-1'}>
      <label for="timezone-select" className={'block font-secondary'}>Spiritual Service</label>
      <select onChange={(e) => setFormService(e.target.value)} className={'font-secondary py-[0.375rem] px-2 input border border-giek-purple rounded-md text-lg w-full input-bordered'} id="timezone-select">
        <option value =""></option>
        <option value="channelled-personal-messages">Channeled Personal Messages (Tarot / Astrology Transits)</option>
        <option value="personal-channelled-love-messages">Personal Channeled Love Messages (Tarot)</option>
        <option value="general-life-purpose">General Life Purpose Astrology Reading</option>
        <option value="past-life-tarot">Past Life Tarot and Astrology Reading</option>
        <option value="distance-energy-healing">Distance Energy Healing/Reiki </option>
        <option value="magick-healing-sigil">Magick Healing Sigil Ritual Session</option>
        <option value="spirital-awakening-coaching">Spiritual Awakening Coaching Session</option>
      </select>
      </div>
    </div>}
    <div className={'grid grid-cols-2'}>
      <div className={'form-control col-span-1 p-2 my-1'}>
        <label className={'block font-secondary'}>Name</label>
        <input onChange={(e) => setFormName(e.target.value)} type="text" className={'font-secondary py-1 px-2 input border border-giek-purple rounded-md text-lg w-full input-bordered'}></input>
      </div>
      <div className={'form-control col-span-1 p-2 my-1'}>
        <label className={'block font-secondary'}>Email</label>
        <input onChange={(e) => setFormEmail(e.target.value)} type="text" className={'font-secondary py-1 px-2 input border border-giek-purple rounded-md text-lg w-full input-bordered'}></input>
      </div>
    </div>
    <div className={'grid grid-cols-2'}>
      <div className={'form-control col-span-1 p-2 my-1'}>
      <label for="timezone-select" className={'block font-secondary'}>Select your time zone:</label>
      <select onChange={(e) => setFormTimezone(e.target.value)} className={'font-secondary py-[0.375rem] px-2 input border border-giek-purple rounded-md text-lg w-full input-bordered'} id="timezone-select">
      <option value="GMT-12">GMT-12</option>
      <option value="GMT-11">GMT-11</option>
      <option value="GMT-10">GMT-10</option>
      <option value="GMT-9">GMT-9</option>
      <option value="GMT-8">GMT-8</option>
      <option value="GMT-7">GMT-7</option>
      <option value="GMT-6">GMT-6</option>
      <option value="GMT-5">GMT-5</option>
      <option value="GMT-4">GMT-4</option>
      <option value="GMT-3">GMT-3</option>
      <option value="GMT-2">GMT-2</option>
      <option value="GMT-1">GMT-1</option>
      <option value="GMT" selected>GMT</option>
      <option value="GMT+1">GMT+1</option>
      <option value="GMT+2">GMT+2</option>
      <option value="GMT+3">GMT+3</option>
      <option value="GMT+4">GMT+4</option>
      <option value="GMT+5">GMT+5</option>
      <option value="GMT+6">GMT+6</option>
      <option value="GMT+7">GMT+7</option>
      <option value="GMT+8">GMT+8</option>
      <option value="GMT+9">GMT+9</option>
      <option value="GMT+10">GMT+10</option>
      <option value="GMT+11">GMT+11</option>
      <option value="GMT+12">GMT+12</option>
      </select>
      </div>
      <div className={'form-control col-span-1 p-2 my-1'}>
      <label for="timezone-select" className={'block font-secondary'}>Preferred Time of Day</label>
      <select onChange={(e) => setFormTimeOfDay(e.target.value)} className={'font-secondary py-[0.375rem] px-2 input border border-giek-purple rounded-md text-lg w-full input-bordered'} id="timezone-select">
        <option value="mornings">Mornings</option>
        <option value="afternoons">Afternoons</option>
        <option selected value="evenings">Evenings</option>
        <option value="late-evenings">Late Evenings</option>
      </select>
      </div>
    </div>

    <div className={'form-control col-span-2 p-2 my-1'}>
    <label for="timezone-select" className={'block font-secondary'}>Preferred Day(s) of the Week</label>
      <div className={'w-full flex justify-start px-0 text-sm font-bold font-secondary'}>
        <button onClick={() => setMondayOn(!mondayOn)} className={`w-full py-2 px-2 border-r rounded-tl-lg rounded-bl-lg border border-giek-purple border-r-giek-grey ${mondayOn ? 'bg-giek-purple text-white' : ''}`}><span className={'hidden xs:block'}>Monday</span><span className={'block xs:hidden'}>Mon</span></button>
        <button onClick={() => setTuesdayOn(!tuesdayOn)} className={`w-full py-2 px-2  border-y border-giek-purple border-r border-r-giek-grey ${tuesdayOn ? 'bg-giek-purple text-white' : ''}`}><span className={'hidden xs:block'}>Tuesday</span><span className={'block xs:hidden'}>Tue</span></button>
        <button onClick={() => setWednesdayOn(!wednesdayOn)} className={`w-full py-2 px-2 border-y border-giek-purple border-r border-r-giek-grey ${wednesdayOn ? 'bg-giek-purple text-white' : ''}`}><span className={'hidden xs:block'}>Wednesday</span><span className={'block xs:hidden'}>Wed</span></button>
        <button onClick={() => setThursdayOn(!thursdayOn)} className={`w-full py-2 px-2 border-y border-giek-purple border-r border-r-giek-grey ${thursdayOn ? 'bg-giek-purple text-white' : ''}`}><span className={'hidden xs:block'}>Thursday</span><span className={'block xs:hidden'}>Thu</span></button>
        <button onClick={() => setFridayOn(!fridayOn)} className={`w-full py-2 px-2 border-y border-giek-purple border-r border-r-giek-grey ${fridayOn ? 'bg-giek-purple text-white' : ''}`}><span className={'hidden xs:block'}>Friday</span><span className={'block xs:hidden'}>Fri</span></button>
        <button onClick={() => setSaturdayOn(!saturdayOn)} className={`w-full py-2 px-2 border-y border-giek-purple border-r border-r-giek-grey ${saturdayOn ? 'bg-giek-purple text-white' : ''}`}><span className={'hidden xs:block'}>Saturday</span><span className={'block xs:hidden'}>Sat</span></button>
        <button onClick={() => setSundayOn(!sundayOn)} className={`w-full py-2 px-2 border-y rounded-tr-lg rounded-br-lg border-giek-purple border-r border-r-giek-purple ${sundayOn ? 'bg-giek-purple text-white' : ''}`}><span className={'hidden xs:block'}>Sunday</span><span className={'block xs:hidden'}>Sun</span></button>
      </div>

    </div>

    <div className={'form-control col-span-2 p-2 my-2'}>
      <label className={'block font-secondary'}>Extra Information</label>
      <textarea onChange={(e) => setFormExtraInfo(e.target.value)} type="text" className={'py-1 px-2  input border border-giek-purple rounded-md text-lg w-full input-bordered'}></textarea>
    </div>
    <div className={'w-[calc(100%-12px)] mx-auto drop-shadow-cta p-1 rounded-2xl bg-cta-container-bg flex justify-center inline-block mt-7'}>
      <button onClick={handleSubmit} className={'text-xl font-bold text-giek-purple w-full font-secondary bg-gradient-to-r from-cta-grad-from to-cta-grad-to-alt rounded-xl px-4 py-2 uppercase'}>{contact ? 'Get in Touch' : 'Schedule a Session'}</button>
    </div>
    </>}
    {formHasSubmitted &&
      <div className={'border bg-white mt-8 border-giek-purple rounded-lg p-8 flex justify-center items-center text-primary text-giek-purple'}>
          <p>Thank you for contacting me, I will be in touch shortly!</p>
      </div>
    }
    </div>
    </div>
  )
}

export default AngelForm

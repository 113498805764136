import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import giek from '../assets/images/giek1-transparent.png'
import bg from '../assets/images/rectangle.png';
import {getPage} from '../api'
import FrameSvg from '../components/angel-hero/FrameSvg';
import AngelTextColumn from '../components/angel-text-column/AngelTextColumn'
import PinwheelSvg from '../components/angel-manifesto/PinwheelSvg.js';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID

export const GiekHighPriestess = ({}) => {

  const PAGE_ID = 467
  const [aboutContent, setAboutContent] = useState(null)

  useEffect(() => {
    ReactGA.initialize([{ trackingId: TRACKING_ID }]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Giek_1 High Priestess" });
  }, []);

  const getAbout = () => {
    getPage(PAGE_ID).then(result => {
      setAboutContent([
        {
          para_one: result.acf.section_1.top_paragraph,
          para_two: result.acf.section_1.bottom_paragraph,
          image: result.acf.section_1.image
        },
        {
          title: result.acf.section_2.title,
          para_one: result.acf.section_2.top_paragraph,
          para_two: result.acf.section_2.bottom_paragraph
        },
        {
          title: result.acf.section_3.title,
          para_one: result.acf.section_3.top_paragraph,
          para_two: result.acf.section_3.bottom_paragraph,
          image: result.acf.section_3.image
        },
        {
          title: result.acf.section_4.title,
          para_one: result.acf.section_4.top_paragraph,
          para_two: result.acf.section_4.bottom_paragraph,
          image: result.acf.section_4.image
        },
        {
          title: result.acf.section_5.title,
          para_one: result.acf.section_5.top_paragraph,
          para_two: result.acf.section_5.bottom_paragraph,
          image: result.acf.section_5.image
        }
      ])
      console.log(result)
    })
  }

useEffect(() => {
  getAbout()
  window.scrollTo({
  top:0,
  behavior: 'smooth',
});
}, [])

  return (
    <>
    <div className={'min-h-[100vh]'}>
    <div className={'mt-28 sm:mt-0 flex justify-center w-full sm:mt-8'}>
      <h1 className={'fade-in glowing-purple-text text-giek-purple max-w-screen-sm font-heading text-4xl sm:text-6xl mt-8 w-full text-giek-grey pb-2 text-center uppercase'}>Giek High Priestess</h1>
    </div>
    <div className={'-translate-y-12 xl:-translate-y-0'}>
    {aboutContent && <AngelTextColumn pages={aboutContent.slice(0,1)} />}
    </div>
    </div>

    {aboutContent && <div className={'relative max-w-screen-lg mx-auto sm:mt-16 sm:mb-32 sm:p-8'}>
    <div className={'flex justify-center w-full mt-12 sm:mt-8'}>
      <h2 className={'font-heading pb-2 text-5xl sm:text-6xl text-center sm:text-left text-giek-purple glowing-purple-text mb-0'}>{aboutContent[1].title}</h2>
    </div>
      <div className={'absolute left-0 -top-16 flex w-full justify-center'}>
        <PinwheelSvg />
      </div>
      <div className={' p-8'}>
          <p className={'font-secondary text-3xl sm:text-4xl italic text-center sm:text-left'} dangerouslySetInnerHTML={{__html: aboutContent[1].para_one}}></p>
      </div>

      <div className={'p-8'}>
        <p className={'font-primary text-xl'} dangerouslySetInnerHTML={{__html: aboutContent[1].para_two}}></p>
      </div>
    </div>}
    {aboutContent && <AngelTextColumn pages={aboutContent.slice(2)} />}
    <div className={'max-w-screen-lg  mx-auto grid sm:grid-cols-2 w-full mb-16 sm:mt-16 sm:mb-16 flex justify-center'}>
      <div className={'p-4'}>
      <Link to={'/spiritual-guidance'}>
        <div className={' drop-shadow-cta w-full rounded-xl mx-auto bg-gradient-to-r from-cta-grad-from to-cta-grad-to shadow flex items-center'}>
          <div className={'w-full'}>
            <div className={'h-[180px] flex items-center'}>
              <h4 className={'font-heading text-center text-5xl text-outlined'}>Spiritual Guidance</h4>
              </div>
          </div>
        </div>
        </Link>
      </div>
      <div className={'p-4'}>
      <Link to={'/channelled-art-and-performances'} className={' w-full z-[999999]'}>
        <div className={'drop-shadow-cta w-full rounded-xl mx-auto bg-gradient-to-r from-cta-grad-to to-cta-grad-from shadow flex items-center'}>
          <div className={'w-full'}>
            <div className={'h-[180px] flex items-center'}>
              <h4 className={'font-heading text-center w-full text-5xl text-outlined'}>Channelled Art</h4>
              </div>
          </div>
        </div>
        </Link>
      </div>
    </div>

      </>
  )
}

export default GiekHighPriestess;

export const PinwheelSvg = () => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="39" viewBox="0 0 42 39" fill="none">
  <path d="M26.2429 12.3916V12.6538C26.172 15.8369 24.8223 18.7384 23.4453 20.8831C22.7406 21.9808 22.0355 22.8695 21.5073 23.483C21.3035 23.7197 21.1264 23.915 20.9862 24.0652C20.846 23.915 20.6689 23.7197 20.4652 23.483C19.937 22.8695 19.2319 21.9808 18.5271 20.8831C17.1124 18.6797 15.7266 15.6774 15.7266 12.3916C15.7266 9.10589 17.1124 6.10362 18.5271 3.90016C19.2319 2.80252 19.937 1.91377 20.4652 1.30025C20.6689 1.06366 20.8459 0.868376 20.9861 0.718203C21.1262 0.868363 21.3032 1.06362 21.5067 1.30015C22.0347 1.91367 22.7394 2.80242 23.4438 3.90006C24.8578 6.10351 26.2429 9.10582 26.2429 12.3916Z" stroke="black"/>
  <path d="M21.6796 25.6768C21.583 25.3782 21.5081 25.1243 21.4526 24.9258C21.6535 24.8835 21.9121 24.8329 22.22 24.7809C23.0168 24.6463 24.14 24.5028 25.441 24.4679C28.0529 24.3979 31.3307 24.767 34.1325 26.4709C36.9345 28.1748 38.7764 30.9194 39.9221 33.2749C40.4928 34.4482 40.8851 35.5135 41.1345 36.2845C41.231 36.583 41.306 36.837 41.3614 37.0354C41.1606 37.0778 40.9019 37.1284 40.594 37.1804C39.7972 37.315 38.6741 37.4585 37.373 37.4933C34.7611 37.5633 31.4833 37.1942 28.6815 35.4904C25.8796 33.7865 24.0377 31.0419 22.892 28.6864C22.3213 27.513 21.9289 26.4478 21.6796 25.6768Z" stroke="black"/>
  <path d="M7.87683 26.4709C10.6786 24.767 13.9564 24.3979 16.5683 24.4679C17.8694 24.5028 18.9925 24.6463 19.7893 24.7809C20.0972 24.8329 20.3559 24.8835 20.5568 24.9258C20.5013 25.1243 20.4263 25.3782 20.3298 25.6768C20.0804 26.4478 19.6881 27.513 19.1174 28.6864C17.9717 31.0419 16.1298 33.7865 13.3278 35.4904C10.526 37.1942 7.24823 37.5633 4.63635 37.4933C3.33527 37.4585 2.21215 37.315 1.41535 37.1804C1.10745 37.1284 0.848764 37.0778 0.647915 37.0354C0.70337 36.837 0.778334 36.583 0.8749 36.2845C1.12424 35.5135 1.5166 34.4482 2.0873 33.2749C3.233 30.9194 5.07488 28.1748 7.87683 26.4709Z" stroke="black"/>
  </svg>
)
}
export default PinwheelSvg

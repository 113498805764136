export const Filter = ({filters, activeFilter, handleActiveFilter}) =>  {
  return (
    <div className={'flex justify-center px-4 flex-wrap max-w-screen-md mx-auto my-8 mb-16 text-center'}>
      {filters.map((filter, i) => {
        return (
          <button key={i} onClick={() => handleActiveFilter(filter)} className={`${activeFilter == filter ? 'bg-giek-purple text-white' : 'text-white blur-panel shadow shadow-giek-violet bg-giek-violet/80 tracking-widest font-extrabold'} fade-in font-secondary uppercase font-bold  rounded-xl px-2 py-1 mr-2 mb-2`}>
            {filter.name}
          </button>
        )
      })}
    </div>
  );
}

export default Filter;

import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Modal, Contact7, AngelForm} from '../'
import {getPostBySlug} from '../../api';
import spiritual from '../../assets/images/spiritual-services.jpg'


export const AngelService = ({service}) => {

  const location = useLocation();
  const [post, setPost] = useState(null)
  const [modalOpen, setModalOpen] = useState(null)

  const getSpiritualServices = (slug) => {
    // alert(slug)
    getPostBySlug(slug).then(result => {
      console.log(result)
      setPost(result.map(post => ({
        id: post.id,
        title: post.title.rendered,
        subtitle: post.acf.post_subtitle,
        blurb: post.excerpt.rendered,
        content: post.content.rendered,
        rate_1: {duration: post.acf.spiritual_service.rate_1.duration, fee: post.acf.spiritual_service.rate_1.fee},
        rate_2: {duration: post.acf.spiritual_service.rate_2.duration, fee: post.acf.spiritual_service.rate_2.fee},
        rate_3: {duration: post.acf.spiritual_service.rate_3.duration, fee: post.acf.spiritual_service.rate_3.fee},
        // image: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
        slug: post.slug,
        // historical_dates: post.acf.historical_dates,
        // service: {url: post.acf.post_link_1.link_url, label: post.acf.post_link_1.link_text}
      })))
    })
  }

  useEffect(() => {
    getSpiritualServices(location.pathname.split('/').pop())
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
    <div className={'min-h-screen'}>
    { post && post.map((p) => {
      return (
        <>
        <div className={'max-w-screen-sm w-full mx-auto px-8'}>
          <div className={' mt-20 sm:mt-12  flex justify-center w-full'}>
              <h1 className={'fade-in glowing-purple-text text-giek-dark-violet max-w-screen-sm font-heading text-5xl sm:text-6xl mt-8 w-full pb-2 text-center uppercase'} dangerouslySetInnerHTML={{__html: p.title}}></h1>
          </div>
          <h5 className={'font-primary text-center text-2xl sm:text-3xl text-center font-bold text-waw-purple py-8 my-16 sm:py-0'} dangerouslySetInnerHTML={{__html: p.blurb}}></h5>
          <p className={'blog-content font-secondary text-lg sm:text-xl mt-4 text-center sm:text-left'} dangerouslySetInnerHTML={{__html: p.content}}></p>
        </div>
        <div className={'max-w-screen-sm mx-auto flex justify-center'}>
          <div className={'p-12 px-8 sm:p-8 font-bold'}>
            <div className={'bg-invert-blur rounded-lg py-8 border-t border-b border-t-giek-gray border-b-giek-gray'}>
              <h5 className={'font-heading glowing-purple-text text-center w-full text-6xl text-giek-dark-violet mt-8'}>Rates</h5>
              <div className={'my-8'}>
              <p className={'underline px-2 sm:px-8 sm:py-2 py-1 font-secondary text-xl italic text-center'}>{p.rate_1.duration}</p>
              <p className={'mb-4 px-2 max-w-[240px] mx-auto text-center sm:px-8 sm:py-2 py-1 font-secondary text-lg'}>{p.rate_1.fee}</p>
              <p className={'underline px-2 sm:px-8 sm:py-2 py-1 font-secondary text-xl italic text-center'}>{p.rate_2.duration}</p>              
              <p className={'mb-4 px-2 max-w-[240px] mx-auto text-center sm:px-8 sm:py-2 py-1 font-secondary text-lg'}>{p.rate_2.fee}</p>    
              {p.rate_3.duration &&
                <>
                    <p className={'underline px-8 sm:py-2 py-1 font-secondary text-xl italic text-center'}>{p.rate_3.duration}</p>
                    <p className={'px-8 max-w-[240px] mx-auto text-center sm:py-2 py-1 font-secondary text-lg'}>{p.rate_3.fee}</p>
                </>
              }
              </div>
              <p className={'p-8 py-4 font-primary font-bold text-giek-dark-violet text-md italic text-center w-full'}>All information provided by you is kept confidential.</p>
              <div className={'mb-4 w-[260px] sm:w-[360px] mx-auto drop-shadow-cta p-1 rounded-2xl bg-cta-container-bg flex justify-center inline-block mt-2'}>
                <button onClick={() => setModalOpen(true)} className={'text-lg sm:text-xl font-bold text-giek-dark-violet w-full font-secondary bg-gradient-to-r from-cta-grad-from to-cta-grad-to-alt rounded-xl px-4 py-2 uppercase'}>Schedule a Session</button>
              </div>
            </div>
          </div>
        </div>
        </>
      )
    })

      }

    </div>
    { post &&
    <Modal open={modalOpen} component={<AngelForm service={post[0].title} />} handleModalOpen={() => setModalOpen(false)} />
}
    </>
  )
}

export default AngelService;

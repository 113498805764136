import {useContext, createContext, useState, useEffect} from 'react'

const CursorContext = createContext()

export const CursorContextProvider = ({children}) => {

    const [isHolding, setIsHolding] = useState(null)
    const [isHovering, setIsHovering] = useState(null)

    return (
        <CursorContext.Provider value={{isHolding, setIsHolding, isHovering, setIsHovering}}>{children}</CursorContext.Provider>
    )
}

export const CursorActions = () => {
    return useContext(CursorContext)
}
import React, {useEffect, useState} from 'react';
import './Navigation.css'
import NavigationIcon from './NavigationIcon';
import { Link }from 'react-router-dom';
import Image from '../image/Image';
import logo from '../../../assets/images/giek1-transparent.png';

export const Navigation = () => {
  const [sidebarActive, setSidebarActive] = useState(false)
  const [hideNavigation, setHideNavigation] = useState(true)
  useEffect(() => {
    if(window.location.pathname == '/') {
      setHideNavigation(true)
    } else {
      setHideNavigation(false)
    }
  }, [window.location.pathname])
  return (
    <>
    {/* { !hideNavigation && */}
    <div className={'w-full slide-top'}>
      <nav className={'sm:bg-transparent z-[9999999] fixed max-w-[100vw] w-full mx-auto flex justify-between fade-in sm:pl-4 sm:pr-4 lg:pl-0 lg:pr-0'}>
        <div className={' 2xl:translate-x-4 -translate-x-[8px] sm:translate-x-0 translate-y-[4px] sm:translate-y-0  flex justify-between w-[90px] md:w-[100px] top-1 sm:top-1 md:top-2 lg:top-3 scale-[1.333] sm:scale-[1.5] sm:-12 px-1 sm:px-2 sm:pt-1 sm:pb-2 lg:pt-2 relative display'}>
          <Link to={'/'} className={''}><Image src={logo} /></Link>
        </div>
        <div onClick={() => setSidebarActive(true)}>
          <svg className="translate-y-[4px] z-[999999999999] sm:translate-y-[0] 2xl:-translate-x-8 cursor-pointer px-1 top-1 w-12 h-12 sm:w-12 sm:h-20 stroke-giek-purple" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
            <path  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
      </nav>
      <div className={`bg-cta-grad-to-alt z-[999999999999] border-l border-l-giek-grey w-[320px] h-[100vh]  top-0 transition duration-300 ease-in-out z-50 fixed left-[100vw] z-[99999999] ${sidebarActive ? '-translate-x-[320px]' : 'translate-x-[320px]'}`}>
      <svg onClick={() => setSidebarActive(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="stroke-giek-purple fill-giek-purple cursor-pointer w-12 h-12  float-right mt-4 mb-4 mr-[260px]">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
        <ul className={'text-giek-purple  font-bold font-secondary uppercase  text-2xl mt-20'}>
        <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/giek-high-priestess'}>Giek High Priestess</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/channelled-art-and-performances'}>Art & Performances</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/spiritual-guidance'}>Spiritual Guidance</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/healing-gatherings'}>Healing Gatherings</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/press'}>Press</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><a href={'https://giekonstruct.onlineweb.shop'} target="_blank">Webshop</a></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><a href={'https://dekunstdragers.nl/stephanie-smit-giek/'} target="_blank">Kunstdragers</a></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/contact'}>Contact</Link></li>
          <p className={'font-primary text-center w-full text-lg font-xl text-giek-purple pb-2 pt-8'}>Become a follower of the Reality Cult</p>

          <li className={'flex flex-start gap-3 mt-[12px] ml-4'}>
            <a href="https://giek-1.bandcamp.com" target="_blank"><NavigationIcon type={'bandcamp'}/></a>
            <a href="https://www.instagram.com/realitycult.3.33.333/" target="_blank"><NavigationIcon type={'instagram'}/></a>
            <a href="https://open.spotify.com/artist/2qMBswfUwJpBeTKapK1ztf?si=PWqthRYrSBWLbGTUKYL28w&nd=1" target="_blank"><NavigationIcon type={'spotify'}/></a>
            <a href="https://www.facebook.com/profile.php?id=100092570799154" target="_blank"><NavigationIcon type={'facebook'}/></a>
            <a href="https://www.linkedin.com/in/stephanie-smit-6a711237/" target="_blank"><NavigationIcon type={'linkedin'}/></a>
            <a href="https://soundcloud.com/giek_1" target="_blank"><NavigationIcon type={'soundcloud'}/></a>
            <a href="https://www.youtube.com/@Giek1" target="_blank"><NavigationIcon type={'youtube'}/></a>
          </li>
        </ul>
      </div>
    </div>
  {/* } */}
    </>
  )
}

export default Navigation;

import React, {useState, useEffect} from 'react';
import {useHref, useNavigate} from 'react-router-dom'
import {format} from 'date-fns'
import { getPostBySlug } from '../api';
import { Image } from '../components/index'
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID



export const Single = () => {
  const history = useHref()

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const [post, setPost] = useState(null)
  const [gallery, setGallery] = useState(null)
  useEffect(() => {
      const slug = history.split('/').pop()
      console.log(slug)
      getPostBySlug(slug).then(result => {
        const lePost = {
              id: result[0].id,
              date: result[0].date,
              monthYear: format(new Date(result[0].date), "yy-MM"),
              title: result[0].title.rendered,
              excerpt: result[0].excerpt.rendered,
              content: result[0].content.rendered,
              slug: result[0].slug,
              audio: result[0].acf.podcast_spotify,
              banner: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.source_url,
              // thumbnail: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        }
        ReactGA.initialize([{ trackingId: TRACKING_ID }]);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: lePost.title });
        
        let urlArr = extractAllTheImageUrls(lePost.content)
        let widthArr = extractAllTheImageWidths(lePost.content)
        let heightArr = extractAllTheImageHeights(lePost.content);

        let newArr = []

        urlArr.forEach((url, i) => {
          newArr.push({
            url: url,
            width: widthArr[i],
            height: heightArr[i],
            orientation: widthArr[i] > heightArr[i] ? 'landscape' : 'portrait'
          })
        })
        
        setGallery(newArr)
        lePost.content = removeImgTags(lePost.content);
        setPost(lePost)
      })
      window.scrollTo(0, 0);
  }, [])

  const extractAllTheImageUrls = (htmlText) => {
    const imgSrcRegex = /<img[^>]+src="([^"]+)"(?!.*youtube)/g;
    const imgUrls = [];
    let match;

    while ((match = imgSrcRegex.exec(htmlText)) !== null) {
      imgUrls.push(match[1]);
    }

    return imgUrls;
  }

  const extractAllTheImageWidths = (htmlText) => {
    const imgWidthRegex = /<img[^>]+width="(\d+)"/g;
    const imgWidths = [];
    let match;
  
    while ((match = imgWidthRegex.exec(htmlText)) !== null) {
      imgWidths.push(parseInt(match[1], 10));
    }
  
    return imgWidths;
  };

  const extractAllTheImageHeights = (htmlText) => {
    const imgHeightRegex = /<img[^>]+height="(\d+)"/g;
    const imgHeights = [];
    let match;
  
    while ((match = imgHeightRegex.exec(htmlText)) !== null) {
      imgHeights.push(parseInt(match[1], 10));
    }
  
    return imgHeights;
  };
  
  

  function removeImgTags(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const imgTags = doc.querySelectorAll('img');
    imgTags.forEach(img => {
        img.remove();
    });
    return doc.body.innerHTML;
}

  useEffect(() => {
  const parentContainer = document.getElementById('blog');
  if (parentContainer) {
    const imgElements = parentContainer.querySelectorAll('img');
    const handleClick = (event) => {
      window.open(event.target.src);
    };

    imgElements.forEach((img, i) => {
      img.addEventListener('click', handleClick);
    });

    return () => {
      imgElements.forEach((img) => {
        img.removeEventListener('click', handleClick);
      });
    };
  }

}, [post]);

  return (
    <>
      <div onClick={handleBack} className={'cursor-pointer fixed top-24 left-4 font-heading text-giek-purple font-extrabold text-4xl z-[9]'}>BACK</div>
      <div className={'main min-h-[100vh] pb-16'} id={'blog'}>
      { post &&
        <>
        <div className={'fade-in  bg-giek-dark-violet h-screen max-w-screen-lg md:max-w-none mx-auto flex items-center justify-center'}>
          {gallery.filter(g => g.orientation == 'landscape')[0] &&
            <div className={'hidden md:block w-full brightness-[0.5] contrast-[0.75] h-full overflow-hidden fade-in absolute top-0 left-0'}><img src={gallery.filter(g => g.orientation == 'landscape')[0].url}/></div>
          }

          {gallery.filter(g => g.orientation == 'portrait')[0] &&
            <div className={'block md:hidden w-full brightness-[0.5] contrast-[0.75] h-full overflow-hidden fade-in absolute top-0 left-0'}><img src={gallery.filter(g => g.orientation == 'portrait')[0].url}/></div>
          }

          <h1 className={'relative z-[2] fade-in-slow px-8 font-heading text-white glowing-purple-text text-center w-full text-4xl md:text-6xl'} dangerouslySetInnerHTML={{__html: post?.title}}></h1>
        </div>
        <div className={'max-w-screen-lg mx-auto p-4'}>
          {/*<Image src={post.banner} />*/}
          {post.audio && <p dangerouslySetInnerHTML={{__html: post.audio}}></p>}
          <div className={'blog-content text-giek-dark-violet font-secondary text-xl tracking-wide sm:text-xl py-4'} dangerouslySetInnerHTML={{__html: post.content }}></div>
          <div className={'md:grid md:grid-cols-3 gap-2'}>
              {
                gallery.map(image => {
                  return (
                    <img className={'w-full h-auto cursor-pointer'} src={image.url} />
                  )
                })
              }
          </div>
        </div>
        </>
      }
      </div>
      { post &&
        <Helmet>
        <title>{post.title}</title>
        <meta property="og:title" content={post.title} />
        <meta name="description" content={post.excerpt} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.banner} />
        </Helmet>
      }
    </>
  )
}

export default Single;

import {useState, useEffect} from 'react'
import { Navigation, AngelHero, AngelManifesto, AngelSkybox, AngelCardSelector, AngelParticles, AngelThreeRotate, AngelCursor, AngelInteractable, AngelIntro } from '../components';
import {Link} from 'react-router-dom'

import DOMPurify from 'dompurify';
import {getPage, getPosts} from '../api'
import {format} from 'date-fns'
import cloud1 from '../assets/images/cloud-01.png';
import cloud2 from '../assets/images/cloud-02.png';
import cloud3 from '../assets/images/cloud-03.png';
import decoration from '../assets/images/neo-tribal-decoration.png'
import halo from '../assets/images/neotribal-halo-giek.png';

import ReactGA from 'react-ga4';
import { CursorActions } from '../context/CursorContext';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID

export const Home = ({handleAudio}) =>  {

  const PAGE_ID = 434
  const MANIFESTO_ID = 7;

  const [slider, setSlider] = useState(null)
  const [manifesto, setManifesto] = useState(null)
  const [nextSectionActive, setNextSectionActive] = useState(false)
  const {isHolding} = CursorActions()

  useEffect(() => {
    ReactGA.initialize([{ trackingId: TRACKING_ID }]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
  }, []);

  const getManifestos = () => {
    getPosts(1, MANIFESTO_ID).then(result => {

      setManifesto(result.data.reverse().map(post => ({
        id: post.id,
        title: post.title.rendered,
        content: post.content.rendered,
        // image: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
        slug: post.slug
      })))
    })
  }

  const getSlider = () => {
    getPage(PAGE_ID).then(result => {
      console.log(result);
      setSlider([
        {
          title: result.acf.slide_1.title,
          subtitle: result.acf.slide_1.subtitle,
          cta: result.acf.slide_1.cta,
          copy: result.acf.slide_1.lower_text,
          image: result.acf.slide_1.image,
          link: `/${result.acf.slide_1.link.post_name}`
        },
        {
          title: result.acf.slide_2.title,
          subtitle: result.acf.slide_2.subtitle,
          cta: result.acf.slide_2.cta,
          copy: result.acf.slide_2.lower_text,
          image: result.acf.slide_2.image,
          link: `/${result.acf.slide_2.link.post_name}`
        },
        {
          title: result.acf.slide_3.title,
          subtitle: result.acf.slide_3.subtitle,
          cta: result.acf.slide_3.cta,
          copy: result.acf.slide_3.lower_text,
          image: result.acf.slide_3.image,
          link: `/${result.acf.slide_3.link.post_name}`
        },
        {
          title: result.acf.slide_4.title,
          subtitle: result.acf.slide_4.subtitle,
          cta: result.acf.slide_4.cta,
          copy: result.acf.slide_4.lower_text,
          image: result.acf.slide_4.image,
          link: `/${result.acf.slide_4.link.post_name}`
        },
        {
          title: result.acf.slide_5.title,
          subtitle: result.acf.slide_5.subtitle,
          cta: result.acf.slide_5.cta,
          copy: result.acf.slide_5.lower_text,
          image: result.acf.slide_5.image,
          link: `/${result.acf.slide_5.link.post_name}`
        }
      ])
    })
  }

  useEffect(() => {
    getSlider()
    getManifestos()
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="cursor-none relative fade-in main w-full min-h-screen">
      {/* {!nextSectionActive ? 
      <AngelIntro slider={slider} handleComplete={setNextSectionActive}/>
      :
      <AngelCardSelector />
      } */}
      <AngelCursor />
      <div className={'relative md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg mx-auto min-h-[calc(100vh+130px)] '}>
        {slider && <AngelHero slider={slider} />}
      </div>
      { manifesto &&
      <div>
        <AngelManifesto manifesto={manifesto} />
      </div>
      }
      
      <div className={'max-w-screen-lg  mx-auto grid sm:grid-cols-3 w-full mb-16 sm:mt-16 sm:mb-16 flex justify-center'}>
        <div className={'p-4'}>
        <Link to={'/spiritual-guidance'}>
          <div className={' drop-shadow-cta w-full rounded-xl mx-auto bg-gradient-to-r from-cta-grad-to to-cta-grad-from shadow flex items-center'}>
            <div className={'w-full'}>
              <div className={'h-[180px] flex items-center'}>
                <h4 className={'font-heading text-center text-4xl text-outlined'}>Spiritual Guidance</h4>
                </div>
            </div>
          </div>
          </Link>
        </div>
        <div className={'p-4'}>
        <Link to={'/channelled-art-and-performances'} className={' w-full z-[999999]'}>
          <div className={'drop-shadow-cta w-full rounded-xl mx-auto bg-gradient-to-r from-cta-grad-to to-cta-grad-from shadow flex items-center'}>
            <div className={'w-full'}>
              <div className={'h-[180px] flex items-center'}>
                <h4 className={'font-heading text-center w-full text-4xl text-outlined'}>Channelled Art</h4>
                </div>
            </div>
          </div>
          </Link>
        </div>
        <div className={'p-4'}>
        <Link to={'/healing-gatherings'} className={' w-full z-[999999]'}>
          <div className={'drop-shadow-cta w-full rounded-xl mx-auto bg-gradient-to-r from-cta-grad-to to-cta-grad-from shadow flex items-center'}>
            <div className={'w-full'}>
              <div className={'h-[180px] flex items-center'}>
                <h4 className={'font-heading text-center w-full text-4xl text-outlined'}>Healing Gatherings</h4>
                </div>
            </div>
          </div>
          </Link>
        </div>
      </div>



    </div>
  );
}

export default Home;

import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navigation, Footer, Player, AngelService, AngelSkybox } from './components';
import { Home, ArtPerformances, GiekHighPriestess, SpiritualGuidance, HealingGatherings, Single, Contact, Giekonstruct, Press } from './pages';
import bg from './assets/images/rectangle.png';
import giek from './assets/images/giek1-transparent.png'
import ReactGA from 'react-ga4';
import { CursorContextProvider } from './context/CursorContext';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID

function App() {

  const [playerAudio, setPlayerAudio] = useState(null)

  useEffect(() => {
    ReactGA.initialize([{ trackingId: TRACKING_ID }]);
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
  }, []);

  return (
    <>
    <CursorContextProvider>
      <div className="max-w-[100vw] main overflow-x-hidden">
        <div className={'fixed fade-in left-0 max-w-[100vw] h-full -z-[3]'}>
          <img className={'min-h-[100vh] max-w-[100vw]'} src={bg} />
          <div className={'fixed left-0 max-w-[100vw] w-full h-full bottom-0 -z-[3] scale-x-[1] -scale-y-[1]'}>
          <img className={'min-h-[100vh] max-w-[100vw]'} src={bg} />
          </div>
        </div>
      <Router>
        {/*showMobileHeaderMenu && <Navigation />*/}
        <Navigation />
        <Routes>
          <Route path={'/'} element={<Home handleAudio={setPlayerAudio} />} />
          <Route path={'/giek-high-priestess'} element={<GiekHighPriestess />} />
          <Route path={'/channelled-art-and-performances'} element={<ArtPerformances handleAudio={setPlayerAudio} />} />
          <Route path={'/spiritual-guidance'} element={<SpiritualGuidance />} />
          <Route path={'/spiritual-guidance/:slug'} element={<AngelService />} />
          <Route path={'/contact'} element={<Contact />} />
          <Route path={'/healing-gatherings'} element={<HealingGatherings handleAudio={setPlayerAudio} />} />
          <Route path={'/works/:id'} element={<Single />} />
          <Route path={'/giekonstruct'} element={<Giekonstruct />} />
          <Route path={'/press'} element={<Press />} />
        </Routes>
      </Router>
      {/* <AngelSkybox /> */}
      </div>
      <div className={'w-full overflow-x-hidden'}>
        <Footer />
        <Player audio={playerAudio} clearAudio={() => setPlayerAudio(null)}/>
      </div>
    </CursorContextProvider>
    </>
  );
}

export default App;

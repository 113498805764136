import React, {useEffect} from 'react'
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID

export const Giekonstruct = () => {
  useEffect(() => {
  // Redirect to the external URL when the component is mounted
    ReactGA.initialize([{ trackingId: TRACKING_ID }]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Webshop Redirect" });
  window.location.href = 'https://giekonstruct.onlineweb.shop';
}, []); // The empty dependency array ensures this effect runs only once on component mount

return (
  <div>
  </div>
);
};

export default Giekonstruct;

import React, {useState, useEffect} from 'react';
import FrameSvg from '../angel-hero/FrameSvg';
import PinwheelSvg from '../angel-manifesto/PinwheelSvg.js';


export const AngelTextColumn = ({pages}) => {
    const [activePageIndex, setActivePageIndex] = useState(0)
  return (
    <>
      { pages && pages.map((pg, i) => {
        return (
          <>
          <div className={`fade-in mx-auto text-giek-dark-violet py-12`}>
          <div className={`max-w-screen-lg justify-center sm:flex items-stretch relative  mx-auto ${i % 2 == 0 ? 'flex-row-reverse' : 'flex-row'}`}>
            <div className={'sm:p-4 sm:px-8 w-full sm:w-3/6'}>
            <div className={`hidden absolute w-full top-10 ${i % 2 ? 'left-0' : 'left-[100%]'}`}>
              <FrameSvg />
            </div>
              <div onClick={() => i !== 0 ? setActivePageIndex(activePageIndex - 1) : null} className={'px-20 sm:px-4 sm:p-4  h-full relative '}>
                <div className={'sm:p-3 top-0  h-full left-0'}>
                <div className={' content-card rounded-[260px] mx-auto mt-4 sm:my-4 max-w-[320px] p-4 border border-white'}>
                  <img className={'rounded-[260px]'} src={pg.image} />
                </div>
                </div>
              </div>
            </div>
            <div className={'sm:p-4 w-full sm:w-3/6 relative sm:h-[calc(600px)]'}>
              <div onClick={() => i !== pages.length - 1 ?  setActivePageIndex(activePageIndex + 1)  : null} className={'p-4 px-8 flex h-full rounded-lg px-8  items-center'}>
              <div className={'w-full'}>
                {pg.title && <div className={'flex justify-center sm:justify-start w-full mt-8'}>
                  <h2 className={'font-heading font-bold pb-2 text-4xl text-giek-dark-violet glowing-purple-text text-center sm:text-left mb-0'}>{pg.title}</h2>
                </div>}
                <p className={`font-primary text-2xl sm:text-left text-center sm:py-4`}><span className={`font-heading text-7xl  mr-1 text-giek-purple bg-none glowing-purple-text`}>{pg.para_one.replace(/<\/?[^>]+(>|$)/g, '').slice(0,1)}</span><p className={'inline font-bold'} dangerouslySetInnerHTML={{__html: pg.para_one.replace(/<\/?[^>]+(>|$)/g, '').slice(1)}}></p></p>
                <p className={`font-primary text-lg sm:text-left text-center py-4 font-bold`} dangerouslySetInnerHTML={{__html:pg.para_two}}></p>
                <p className={`font-primary text-lg sm:text-left text-center py-4 font-bold`}>{pg.para_three && pg.para_three}</p>
              </div>
              </div>
            </div>
            </div>
          </div>
          </>
        )
      })
      }
    </>
  )
}

export default AngelTextColumn;

import React, {useState, useEffect} from 'react'
import DoorSvg from './DoorSvg.js'
import ManifestoSvg from './ManifestoSvg.js';
import PinwheelSvg from './PinwheelSvg.js';
import {AngelCardSlider} from '../index'

export const AngelManifesto = ({manifesto}) => {
  return (
    <div className={'min-screen relative flex justify-center py-64'}>
        <div className={'absolute z-[0] w-full h-full left-0 top-0'}></div>
      <div className={'relative  w-full px-8 md:max-w-screen-md mx-auto'}>
        <h2 className={'font-heading text-5xl md:text-8xl text-giek-purple glowing-purple-text w-full text-center'}>MANIFESTO</h2>
        {manifesto.map((m, i) => {
          return (
            <p className={'manifesto-part my-32 text-center text-2xl glowing-purple-text font-primary text-giek-purple tracking-widest leading-12'} key={i} dangerouslySetInnerHTML={{__html: m.content}}></p>
          )
        })}
        {/* <div className={'flex justify-center w-full brightness-[2]'}>
          <PinwheelSvg />
        </div> */}
      </div>
          {/* <AngelCardSlider cards={manifesto} /> */}
    </div>
  )
}

export default AngelManifesto

import React, {useState, useEffect} from 'react';
import PinwheelSvg from '../components/angel-manifesto/PinwheelSvg';
import FrameSvg from '../components/angel-hero/FrameSvg';
import {AngelTextColumn} from '../components'
import {Link} from 'react-router-dom'



import DOMPurify from 'dompurify';
import {getPage, getPosts} from '../api'
import {format} from 'date-fns'
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID


export const HealingGatherings = () => {


  const PAGE_ID = 473
  const CATEGORY_ID = 4

    const [posts, setPosts] = useState(null)
    const [healingGatheringContent, setHealingGatheringContent] = useState(null)
    const [showPastTourDates, setShowPastTourDates] = useState(false);

    useEffect(() => {
      ReactGA.initialize([{ trackingId: TRACKING_ID }]);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Healing Gatherings" });
    }, []);

    const getHealingGatheringContent = () => {
      getPage(PAGE_ID).then(result => {
        console.log(result)
        setHealingGatheringContent([
          {
            tour_dates: result.acf.tour_dates.split('Past GiekGigs')[0],
            past_tour_dates: result.acf.tour_dates.split('Past GiekGigs')[1]
          },
          {
            title: result.acf.section_1.title,
            para_one: result.acf.section_1.top_paragraph,
            para_two: result.acf.section_1.bottom_paragraph,
            image: result.acf.section_1.image
          },
          {
            title: result.acf.section_2.title,
            para_one: result.acf.section_2.top_paragraph,
            para_two: result.acf.section_2.bottom_paragraph,
            image: result.acf.section_2.image
          },
          {
            title: result.acf.section_3.title,
            para_one: result.acf.section_3.top_paragraph,
            para_two: result.acf.section_3.bottom_paragraph,
            image: result.acf.section_3.image
          },
          {
            title: result.acf.section_4.title,
            para_one: result.acf.section_4.top_paragraph,
            para_two: result.acf.section_4.bottom_paragraph,
            image: result.acf.section_4.image
          },
        ])
      })
    }

    const getPerformances = (page) => {
      getPosts(page, CATEGORY_ID).then(result => {
        setPosts(result.data.map(post => ({
          id: post.id,
          title: post.title.rendered,
          subtitle: post.acf.post_subtitle,
          content: post.excerpt.rendered,
          image: post._embedded['wp:featuredmedia'][0].source_url,
          slug: post.slug,
          historical_dates: post.acf.historical_dates,
          link_1: {url: post.acf.post_link_1.link_url, label: post.acf.post_link_1.link_text}
        })))
      })
    }

    useEffect(() => {
      getHealingGatheringContent()
      getPerformances(1)
      window.scrollTo(0, 0);
    }, [])



  return (
    <div className={'min-h-[100vh]'}>
      <div className={'flex justify-center w-full mt-28 sm:mt-8'}>
        <h1 className={'fade-inglowing-purple-text text-giek-purple max-w-screen-sm font-heading text-4xl sm:text-6xl mt-8 w-full text-giek-grey pb-2 text-center uppercase'}>Healing Gatherings</h1>
      </div>
      <div className={'flex justify-center w-full mt-4'}>
        <h2 className={'font-heading font-bold mx-auto text-4xlglowing-purple-text text-giek-purple mb-0 text-center'}>Tour Dates</h2>
      </div>
      <div className={'max-w-screen-lg mx-auto justify-center w-full mt-8 mb-0'}>
      {healingGatheringContent && <p className={'tour-dates font-secondary font-bold py-1 text-lg text-center'} dangerouslySetInnerHTML={{__html: healingGatheringContent[0].tour_dates}}></p>}
      <div className={'flex justify-center'}>
      <p onClick={() => setShowPastTourDates(!showPastTourDates)} className={`${showPastTourDates ? '' : 'bg-giek-dark-violet text-white' } w-[240px] cursor-pointer p-2 border rounded-lg font-primary text-center border-giek-dark-violet text-xl`}>{showPastTourDates ? 'Hide' : 'See'} Past Tour Dates</p>
      </div>
      {healingGatheringContent && showPastTourDates && <p className={'tour-dates font-secondary font-bold py-1 text-lg text-center'} dangerouslySetInnerHTML={{__html: healingGatheringContent[0].past_tour_dates}}></p>}
      </div>
      {healingGatheringContent && <AngelTextColumn pages={healingGatheringContent.slice(1)} />}







    </div>
  )
}

export default HealingGatherings;

import React from 'react';
import './Image.css'

export const Image = ({src, onLoad}) => {
  return (
    <>
      <div className={'image-container'}>
        <img onLoad={() => onLoad ? onLoad : null} src={src ? src : '#'} />
      </div>
    </>
  )
}

export default Image;
